import { Box, Text, VStack } from '@chakra-ui/react';
import { ShippingInfo } from '@/components/types';
import Panel from '@/components/panel';

interface OrderShippingInfoProps {
  shippingInfo: ShippingInfo;
  shippingMethod: string;
}

const OrderShippingInfo = ({ shippingInfo, shippingMethod }: OrderShippingInfoProps) => {
  const { shippingAddress } = shippingInfo || {};

  return (
    <Panel title="Shipping info">
      <VStack align="start" mt="13px" rowGap="0px">
        <Text textStyle="bodySuperSmall" as="b">
          Shipping address
        </Text>
        <Box mt="8px" textStyle="bodySmall" color="secondaryDarkGray.600">
          <Text>{shippingAddress.name}</Text>
          <Text>{shippingAddress.address1}</Text>
          <Text>{shippingAddress.city}</Text>
          <Text>{shippingAddress.country_name}</Text>
        </Box>
        <Text textStyle="bodySuperSmall" as="b" mt="16px">
          Shipping method
        </Text>
        <Text mt="4px" textStyle="bodySmall" color="secondaryDarkGray.600">
          {shippingMethod}
        </Text>
      </VStack>
    </Panel>
  );
};

export default OrderShippingInfo;
