import { useEffect, useState } from 'react';

import Pubnub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';

import AdminDashboard from './AdminDashboard';
import { useMe } from '@/api/auth';
import Config from '@/config';
import { Center } from '@chakra-ui/react';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import AdminDashboardWithPubnub from './AdminDashboardWithPubNub';

const { PUBNUB_PUBLISH_KEY, PUBNUB_SUBSCRIBE_KEY } = Config;

export default function Admin() {
  const [pubnubClient, setPubNubClient] = useState(null);

  const { data: me, isLoading } = useMe();

  const isPayingUser = me?.client?.subscriptionStatus === 'active';

  useEffect(() => {
    if (isLoading || pubnubClient || !isPayingUser) {
      return;
    }

    const pubnub = new Pubnub({
      publishKey: PUBNUB_PUBLISH_KEY,
      subscribeKey: PUBNUB_SUBSCRIBE_KEY,
      userId: me?.id,
    });

    setPubNubClient(pubnub);
  }, [me, isLoading, pubnubClient, isPayingUser]);

  if (isLoading || (isPayingUser && !pubnubClient)) {
    return (
      <Center bg="transparent" h="100vh">
        <LoadingSpinner />
      </Center>
    );
  }

  if (!pubnubClient) {
    return <AdminDashboard me={me} />;
  }

  return (
    <PubNubProvider client={pubnubClient}>
      <AdminDashboardWithPubnub me={me} />
    </PubNubProvider>
  );
}
