import { Box, Center, HStack, Spinner, Stack, Text, useToast, VStack } from '@chakra-ui/react';

import { useHistory, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Cart, Payment, ShippingInfo } from '@/components/types';
import { deleteOrder, getOrder, getOrderPayment, getOrderShipping } from '@/api/cart';
import AppContainer from '@/layouts/AppContainer';
import IconChevronDownBreadCrumb from '@/components/icons/IconChevronBreadCrumb';
import Button from '@/components/button';
import { getDesign } from '@/api/designs';
import { canCancelOrder } from './utils';
import OrderBasicInfo from './components/OrderBasicInfo';
import OrderShippingInfo from './components/OrderShippingInfo';
import OrderPaymentInfo from './components/OrderPaymentInfo';
import OrderProductInfo from './components/OrderProductInfo';

export default function OrderDetails() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const toast = useToast();

  const [order, setOrder] = useState<Cart>(null);
  const [payment, setPayment] = useState<Payment>(null);
  const [shipping, setShipping] = useState<ShippingInfo>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCancelingOrder, setIsCancelingOrder] = useState(false);

  const prepareOrder = useCallback(async () => {
    const resOrder = await getOrder(id);
    const resPayment = await getOrderPayment(resOrder.id);
    setPayment(resPayment);
    const resShipping = await getOrderShipping(resOrder.id);
    setShipping(resShipping);

    // Fill design info
    await Promise.all(
      resOrder?.items.map((item) =>
        getDesign(item.designId).catch(() => {
          return null;
        })
      )
    ).then((designs) => {
      resOrder.designs = designs || [];

      resOrder.totalPrice = resOrder.items.reduce((result, cartItem, index) => {
        const { quantity } = cartItem;

        const design = designs[index];

        return result + (design?.template?.price || 0) * quantity;
      }, 0);
    });
    setOrder(resOrder);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    prepareOrder();
  }, [prepareOrder]);

  const { orderStatus } = order || {};
  const { costs: shippingCosts } = shipping || {};
  const { amount } = payment || {};

  const isCancelVisible = canCancelOrder(orderStatus?.text);

  const handleGoBackToHome = () => history.push(`/`);
  const handleGoBackToOrders = () => history.push(`/orders`);
  const handleCancelOrder = async () => {
    setIsCancelingOrder(true);
    try {
      await deleteOrder(order.id);
      await prepareOrder();
      toast({
        title: 'Order refunded',
      });
    } catch {
      toast({
        title: 'Error cancelling order',
      });
    } finally {
      setIsCancelingOrder(false);
    }
  };

  return (
    <AppContainer contentContainerProps={{ bg: '#FFFFFF', padding: 0 }}>
      <Box bg="#FFFFFF" h="100%">
        <Box position="relative" h={{ base: 'calc(100dvh - 150px)', md: '100%' }} padding="0px">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            align="start"
            justifyContent="space-between"
            h="100%"
          >
            <VStack align="start" p="32px 32px">
              <HStack gap="4px" mb={{ base: '12px', md: '16px' }}>
                <Text color="brand.600" onClick={handleGoBackToHome} textStyle="breadcrumb">
                  Home
                </Text>
                <IconChevronDownBreadCrumb />
                <Text color="brand.600" textStyle="breadcrumb" onClick={handleGoBackToOrders}>
                  Orders
                </Text>
                <IconChevronDownBreadCrumb />
                <Text color="secondaryDarkGray.600" fontWeight={400} textStyle="breadcrumb">
                  Order Details
                </Text>
              </HStack>
              {isLoading ? (
                <Center bg="#FFFFFF" w="100%">
                  <Spinner thickness="1px" speed="0.65s" emptyColor="gray" size="md" />
                </Center>
              ) : (
                <VStack align="start" rowGap="24px">
                  <OrderBasicInfo order={order} />
                  <OrderShippingInfo
                    shippingInfo={shipping}
                    shippingMethod={order.shippingMethod}
                  />
                  <OrderPaymentInfo payment={payment} />
                  {isCancelVisible && (
                    <Button
                      isLoading={isCancelingOrder}
                      onClick={() => handleCancelOrder()}
                      secondary
                      m="24px 0px"
                      w="100%"
                    >
                      Cancel order
                    </Button>
                  )}
                </VStack>
              )}
            </VStack>
            {order?.items?.length && (
              <VStack
                align="start"
                h="100%"
                borderLeftColor="secondaryGrea.600"
                borderLeftWidth="1px"
                p="32px 0px"
              >
                <VStack align="start" p="0 32px">
                  <Text textStyle="subtitle" as="b" mb="9px">
                    Products ({order.items.length})
                  </Text>
                  {order.items.map((item) => {
                    const design = order.designs.find((d) => d.id === item.designId);
                    return design && <OrderProductInfo design={design} item={item} />;
                  })}
                </VStack>
                <HStack
                  marginTop="auto"
                  p="20px 32px"
                  w="100%"
                  borderTopColor="secondaryGrea.600"
                  borderTopWidth="1px"
                >
                  <VStack align="start" rowGap="0">
                    <Text textStyle="subtitle" as="b">
                      Order total:
                    </Text>
                    <Text textStyle="bodySmall" color="secondaryDarkGray.600">
                      Shipping:
                    </Text>
                  </VStack>
                  <VStack align="start" rowGap="0" marginLeft="auto">
                    <Text textStyle="subtitle" as="b">
                      {amount.currencyCode} {amount.amount}
                    </Text>
                    {shippingCosts && (
                      <Text textStyle="bodySmall" color="secondaryDarkGray.600">
                        {shippingCosts.currency} {shippingCosts.shipping} (included)
                      </Text>
                    )}
                  </VStack>
                </HStack>
              </VStack>
            )}
          </Stack>
        </Box>
      </Box>
    </AppContainer>
  );
}
