import { IBackgroundRemovalResponse } from '@space-runners/ablo-ts-sdk';
import { ablo } from './ablo-wrapper';

export const removeBackgroundByUrl = async (
  imageUrl: string
): Promise<IBackgroundRemovalResponse> => {
  const response = await ablo().removeBackground.byUrl(imageUrl);

  return response;
};

export const removeBackgroundByFile = async (
  imageFile: string
): Promise<IBackgroundRemovalResponse> => {
  const response = await ablo().removeBackground.byFile(imageFile);

  return response;
};
