import { Box, Center, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { Cart } from '@/components/types';
import { H3 } from '@/components/typography/Headings';
import { format, parseISO } from 'date-fns';

interface OrderBasicInfoProps {
  order: Cart;
}

const OrderBasicInfo = ({ order }: OrderBasicInfoProps) => {
  const { createdAt, estimatedDeliveryAt, orderStatus } = order || {};

  return (
    <>
      <Box>
        <H3>#{order?.shopifyOrderNumber}</H3>
        <Center
          bgColor={orderStatus?.color}
          borderColor={orderStatus?.borderColor}
          borderWidth="1px"
          borderRadius="4px"
          h="22px"
          mt="6px"
          p="2px 4px"
          w="fit-content"
        >
          <Text textStyle="caption" color={orderStatus.borderColor} fontSize="12px">
            {orderStatus?.text}
          </Text>
        </Center>
      </Box>
      <HStack columnGap="56px">
        <VStack align="start" rowGap="4px">
          <Text textStyle="bodySuperSmall" color="secondaryDarkGray.600">
            Placed on:
          </Text>
          <Text textStyle="bodySmall" as="b">
            {format(parseISO(createdAt), 'MMM d, y')}
          </Text>
        </VStack>
        {estimatedDeliveryAt && (
          <VStack align="start">
            <Text textStyle="bodySuperSmall" color="secondaryDarkGray.600">
              Estimated delivery:
            </Text>
            <Text textStyle="bodySmall" as="b">
              {format(parseISO(estimatedDeliveryAt), 'MMM d, y')}
            </Text>
          </VStack>
        )}
      </HStack>
      <VStack align="start" rowGap="10px" w="376px">
        <HStack
          columnGap="12px"
          p="12px"
          bg="green.300"
          borderLeft="2px solid"
          borderLeftColor={orderStatus.borderColor}
          backgroundColor={orderStatus.color}
        >
          <Center minW="24px">
            <Image src={orderStatus.iconUrl} w="24px" />
          </Center>
          <Text textStyle="body" color="secondaryDarkGray.600">
            {orderStatus.descriptionShort}
          </Text>
        </HStack>
        <Text textStyle="bodySuperSmall" color="secondaryDarkGray.600">
          {orderStatus.descriptionLong}
        </Text>
      </VStack>
    </>
  );
};

export default OrderBasicInfo;
