import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Hide,
  Image,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';

import RemixesList from '@/components/remix/RemixesList';

import IconBack from '@/lib/components/icons/IconBack';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useCategories, useTemplate } from '../../api/templates';
import { CSSProperties, useEffect, useState } from 'react';
import { getDesignVotes, voteForDesign } from '../../api/designs';
import { groupBy, isEmpty } from 'lodash';
import RemixDetailsModal from '../home/RemixDetailsModal';
import { DesignVote, Template, User } from '../../components/types';
import IconChevronDown from '@/components/icons/IconChevronDown';
import AddToCartModal from '@/components/cart/AddToCartModal';
import Brands from './components/Brands';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import Search from '@/components/search';

const IMAGE_DESKTOP_SIZE = 420;

const getVotesForDesigns = (templateId: string, designVotes: DesignVote[]) => {
  const votesForTemplate = designVotes.filter((designVote) => designVote.templateId === templateId);

  return groupBy(votesForTemplate, 'designId');
};

const getStats = (template: Template) => [
  {
    name: 'Total designs',
    value: template.numRemixes,
  },
];

interface CollectionPageProps {
  onBack?: () => void;
  votingForDesign?: string;
  votedForDesign?: string;
  onSignInToVote?: (designId: string) => void;
  onSignInToAddToCart?: (designToAdd: string) => void;
  user?: User;
}

export default function CollectionPage({
  onBack,
  onSignInToVote,
  onSignInToAddToCart,
  user,
}: CollectionPageProps) {
  const { id } = useParams<{ id: string }>();
  const { data: template, isLoading, isError } = useTemplate(id);

  const { data: categories = [] } = useCategories();

  const [designId, setDesignId] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');

  const [designToAddToCart, setDesignToAddToCart] = useState<string>(null);

  const [votingForDesign, setVotingForDesign] = useState<string>(null);
  const [designVotes, setDesignVotes] = useState({});
  const [votedForDesign, setVotedForDesign] = useState<string>(null);

  const history = useHistory();
  const toast = useToast();

  const { search } = useLocation();

  const {
    brands,
    categoryId,
    collectionDescription = '',
    name,
    collectionBannerUrlWide,
    collectionBannerUrlMobile,
  } = template || {};

  const category = categories.find(({ id }) => id === categoryId);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    const addingDesignToCart = queryParams.get('designToAddToCart');

    const votingForDesign = queryParams.get('votingForDesign');

    if (addingDesignToCart) {
      handleAddToCart(addingDesignToCart);
    } else if (votingForDesign) {
      handleVote(votingForDesign);
    }

    getDesignVotes()
      .catch(() => [])
      .then((designVotes) => {
        setDesignVotes(getVotesForDesigns(id, designVotes));
      });
  }, []);

  const handleAddToCart = (designId: string) => {
    if (onSignInToAddToCart) {
      onSignInToAddToCart(designId);

      return;
    }

    setDesignToAddToCart(designId);
  };

  const handleVote = (designToVoteForId: string) => {
    if (onSignInToVote) {
      onSignInToVote(designToVoteForId);

      return;
    }

    setVotingForDesign(designToVoteForId);

    voteForDesign(designToVoteForId)
      .then((newDesignVote) => {
        const votesForDesign = designVotes[designToVoteForId] || [];

        setDesignVotes({
          ...designVotes,
          [designToVoteForId]: [...votesForDesign, newDesignVote],
        });

        setVotedForDesign(designToVoteForId);

        setTimeout(() => {
          setVotedForDesign(null);
        }, 1000);
      })
      .catch((e) => {
        toast({
          title: e.message,
          status: 'error',
        });
      })
      .finally(() => {
        setVotingForDesign(null);
      });
  };

  const handleGoBackToCategory = () => history.push(`/collections?categoryId=${categoryId}`);

  if (isError) {
    toast({
      title: 'Error loading collection',
      status: 'error',
    });
  }

  const { contestEndAt } = template || {};

  const secondsToEnd = Math.floor((new Date(contestEndAt).getTime() - new Date().getTime()) / 1000);

  const votingEnded = !contestEndAt || secondsToEnd <= 0;

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box bg="#FFFFFF" h="100%" overflow="auto" pb="80px">
      {isLoading ? (
        <Center position="absolute" w="100%" h="100%" bg={'transparent'} opacity="0.5" top={0}>
          <LoadingSpinner />
        </Center>
      ) : (
        template && (
          <>
            {onBack ? (
              <Hide above="md">
                <Box p="12px 20px 12px 0">
                  <Button onClick={onBack} variant="ghost">
                    <IconBack />
                  </Button>
                </Box>
              </Hide>
            ) : null}
            <Box minH={{ base: 400, md: 'auto' }} position="relative">
              <Image
                objectFit="cover"
                src={isMobile ? collectionBannerUrlMobile : collectionBannerUrlWide}
                alt="Collection image"
                h={{ base: '100%', md: IMAGE_DESKTOP_SIZE }}
                position={{ base: 'absolute', md: 'static' }}
                left={0}
                top={0}
                w="100%"
              />
              <Box
                bg={{
                  base: 'linear-gradient(85.87deg, rgba(0, 0, 0, 0.7) -12.58%, rgba(0, 0, 0, 0) 90.21%)',
                  md: 'linear-gradient(66.24deg, rgba(0, 0, 0, 0.7) -6.65%, rgba(0, 0, 0, 0) 45.56%);',
                }}
                padding={{ base: '21px 16px', md: 0 }}
                position="absolute"
                h="100%"
                w="100%"
                left={0}
                top={0}
              >
                <HStack
                  gap="4px"
                  position={{ base: 'static', md: 'absolute' }}
                  left="32px"
                  top="24px"
                >
                  <Text
                    as="b"
                    color="#FFFFFF"
                    cursor="pointer"
                    fontSize={{ base: '12px', md: '13px' }}
                    onClick={handleGoBackToCategory}
                  >
                    {category?.name}
                  </Text>
                  <IconChevronDown
                    color="#FFFFFF"
                    height="16px"
                    width="16px"
                    transform="rotate(-90deg)"
                  />
                  <Text color="#FFFFFF" fontSize={{ base: '12px', md: '13px' }} opacity={0.6}>
                    {name}
                  </Text>
                </HStack>
                <Box
                  mt={{ base: '24px', md: 0 }}
                  p={{ base: '0', md: '24px 32px 32px 32px' }}
                  position={{ base: 'static', md: 'absolute' }}
                  left={0}
                  bottom={0}
                >
                  <Brands
                    brands={brands}
                    height={{ base: '47px', md: '51px' }}
                    mb={{ base: '9px', md: '22px' }}
                    mt={{ base: '30px', md: 0 }}
                  />
                  <Heading color="#FFFFFF" fontSize={{ base: '30px', md: '34px' }}>
                    {name}
                  </Heading>
                  <Text
                    color="#FFFFFF"
                    fontSize={{ base: '15px', md: '16px' }}
                    mt={{ base: '3px', md: '7px' }}
                    w={{ base: 'auto', md: '381px' }}
                    display="-webkit-box"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="normal"
                    style={
                      {
                        '-webkit-box-orient': 'vertical',
                        '-webkit-line-clamp': '3',
                      } as CSSProperties
                    }
                  >
                    {collectionDescription}
                  </Text>
                  <HStack mt={{ base: '12px', md: '29px' }} spacing="14px">
                    {getStats(template).map(({ name, value }) => (
                      <Box
                        border="1px solid"
                        borderColor="rgba(255, 244, 244, 0.4)"
                        borderRadius={12}
                        key={name}
                        padding={{ base: '9px 12px', md: '14px 17px' }}
                        w={{ base: '147px', md: '172px' }}
                      >
                        <Text
                          color="#FFFFFF"
                          fontSize={{ base: '13px', md: '16px' }}
                          fontWeight={500}
                        >
                          {name}
                        </Text>
                        <Text color="#FFFFFF" fontSize="24px" fontWeight={700}>
                          {value ? value.toLocaleString() : 0}
                        </Text>
                      </Box>
                    ))}
                  </HStack>
                </Box>
              </Box>
            </Box>
            <VStack
              align="flex-start"
              gap={0}
              p={{ base: '20px 16px', md: '60px 32px 100px 32px' }}
            >
              <Stack
                align={{ base: 'flex-start', md: 'center' }}
                direction={{ base: 'column', md: 'row' }}
                justify="space-between"
                mb="14px"
                w="100%"
              >
                <Heading
                  fontSize={{ base: '20px', md: '30px' }}
                  fontWeight={700}
                  w={{ base: '260px', md: 'auto' }}
                >
                  Limited Merch - Make It Yours Today!
                </Heading>
                <Search value={searchTerm} onChange={setSearchTerm} />
              </Stack>
              <RemixesList
                votingForDesign={votingForDesign}
                onVote={setDesignId}
                onAddToCart={(design) => handleAddToCart(design.id)}
                template={template}
                designVotes={designVotes}
                search={searchTerm}
                votedForDesign={votedForDesign}
                user={user}
              />
            </VStack>
            {designId && (
              <RemixDetailsModal
                designId={designId}
                designVotes={designVotes}
                hasVotingEnded={votingEnded}
                isVoting={votingForDesign === designId}
                onClose={() => setDesignId(null)}
                onVote={!votingEnded && isEmpty(designVotes) ? handleVote : null}
                votedForDesign={votedForDesign}
              />
            )}
            {designToAddToCart && (
              <AddToCartModal
                designId={designToAddToCart}
                goBackText="Back to list"
                onClose={() => setDesignToAddToCart(null)}
              />
            )}
          </>
        )
      )}
    </Box>
  );
}
