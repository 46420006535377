import {
  Grid,
  GridItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Box,
  VStack,
  useToast,
  Image,
  Center,
  Flex,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Design, Template } from '@/lib/types';

import { saveUserDesign } from '@/api/designs';
import { getDefaultTemplates, useCategories } from '../../../api/templates';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import Button from '@/components/button';
import ModalContainer from '@/components/modals/ModalContainer';

const OVERFLOW_STYLE = {
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  'scrollbar-width': 'none',
};

const ARTBOARD_THUMBNAIL_WIDTH = 148;
const ARTBOARD_THUMBNAIL_DESKTOP_WIDTH = 160;

const ARTBOARD_IMAGE_MOBILE_WIDTH = 135;
const ARTBOARD_IMAGE_DESKTOP_WIDTH = 140;

type Props = {
  onCreatedDesign: (design: Design) => void;
  onClose?: () => void;
};

const TemplateSelector = ({ onCreatedDesign, onClose }: Props) => {
  const [selectedTemplate, setSelectedTemplate] = useState<Template>(null);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [waiting, setWaiting] = useState(false);

  const toast = useToast();

  const history = useHistory();

  const { data: categories = [] } = useCategories();

  useEffect(() => {
    getDefaultTemplates().then((templates) => {
      setTemplates(templates);
    });
  }, []);

  const handleClose = () => {
    history.push('/');

    if (onClose) {
      onClose();
    }
  };

  const handleSave = async (template: Template) => {
    setSelectedTemplate(template);

    const { colors, id } = template;

    const sides = template.sides.map((side) => {
      return {
        templateSideId: side.id,
        templateSide: side,
        hasGraphics: false,
        hasText: false,
        designImage: 'default',
        previewImage: 'default',
      };
    });

    const newDesign = {
      name: '',
      sides,
      template: selectedTemplate,
      templateId: id,
      templateColorId: colors[0].id,
    };

    setWaiting(true);

    try {
      const savedDesign = await saveUserDesign(newDesign);

      onCreatedDesign(savedDesign);
    } catch (e) {
      toast({
        title: 'Error creating design',
        description: e.message,
        status: 'error',
      });
    } finally {
      setWaiting(false);
    }
  };

  return (
    <ModalContainer
      modalTitle="Select template"
      onClose={handleClose}
      width={{ base: 'auto', md: '580px' }}
    >
      <Flex direction="column" h="100%" overflowY="auto" css={OVERFLOW_STYLE}>
        <Text color="secondaryDarkGray.600" mb={{ base: '24px', md: '36px' }} textStyle="body">
          Choose a template to create custom merch and start accruing royalties, payable in the near
          future. Unlock a Story Badge with your first sale!
        </Text>
        <Tabs display="flex" flexDirection="column" flex={1} variant="unstyled">
          <Box overflow="auto" css={OVERFLOW_STYLE}>
            <TabList>
              {categories.map((category) => (
                <Tab key={category.id}>{category.name}</Tab>
              ))}
            </TabList>
          </Box>
          <TabPanels flex={1}>
            {categories.map((category) => {
              const templatesForCategory = templates.filter(
                ({ categoryId }) => categoryId === category.id
              );

              return (
                <TabPanel
                  h="100%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  padding="0"
                  key={category.id}
                >
                  <Grid
                    templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }}
                    gap="15px"
                    overflow="auto"
                    padding="16px 0"
                  >
                    {templatesForCategory.map((template) => {
                      const { artboardIconUrl, artboardSizeDescription, id, name } = template;

                      const isSelected = id === selectedTemplate?.id;

                      return (
                        <GridItem
                          as="button"
                          display="flex"
                          onClick={() => handleSave(template)}
                          key={id}
                        >
                          <VStack spacing={0}>
                            <Center
                              borderRadius="14px"
                              bg="#F0F5FF"
                              h={{
                                base: ARTBOARD_THUMBNAIL_WIDTH,
                                md: ARTBOARD_THUMBNAIL_DESKTOP_WIDTH,
                              }}
                              w={{
                                base: ARTBOARD_THUMBNAIL_WIDTH,
                                md: ARTBOARD_THUMBNAIL_DESKTOP_WIDTH,
                              }}
                            >
                              {waiting && isSelected ? (
                                <LoadingSpinner />
                              ) : (
                                <Image
                                  src={artboardIconUrl}
                                  w={{
                                    base: ARTBOARD_IMAGE_MOBILE_WIDTH,
                                    md: ARTBOARD_IMAGE_DESKTOP_WIDTH,
                                  }}
                                />
                              )}
                            </Center>
                            <Text textStyle="body" fontWeight={600} mb="1px" mt="12px">
                              {name}
                            </Text>
                            <Text textStyle="bodySmall">{artboardSizeDescription}</Text>
                          </VStack>
                        </GridItem>
                      );
                    })}
                  </Grid>
                  <Button mt="8px" secondary onClick={handleClose} w="100%">
                    Back home
                  </Button>
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Flex>
    </ModalContainer>
  );
};

export default TemplateSelector;
