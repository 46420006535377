import Button from '@/components/button';
import { Center, Text, VStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

const NoOrdersEmptyState = () => {
  const history = useHistory();

  return (
    <Center
      border="1px solid"
      borderColor="borderSecondary"
      borderRadius="md"
      flex={1}
      h="100%"
      w="100%"
    >
      <VStack spacing={0}>
        <Text mb="1px" fontWeight={600} textStyle="body">
          You have no orders yet
        </Text>
        <Text color="secondaryDarkGray.600" mb="19px" textStyle="bodySmall">
          Shop now and your orders will appear here
        </Text>
        <Button small onClick={() => history.push('/collections')} w="120px">
          Shop now
        </Button>
      </VStack>
    </Center>
  );
};

export default NoOrdersEmptyState;
