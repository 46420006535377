import { ReactNode, useEffect, useState } from 'react';

import { Box, Flex, HStack } from '@chakra-ui/react';

import Button from '@/components/button';
import IconAblo from '@/components/icons/IconAblo';

import SignInModal from '@/views/auth/signin/SignInModal';
import SignUpModal from '@/views/auth/signup/SignUpModal';

interface GuestPageContainerProps {
  children: ReactNode;
  showSignInModal?: boolean;
  onCancelSignIn?: () => void;
  onSignedIn: () => void;
  rightSideNavbarContent?: ReactNode;
}

export default function GuestPageContainer({
  children,
  showSignInModal,
  onCancelSignIn,
  onSignedIn,
  rightSideNavbarContent,
}: GuestPageContainerProps) {
  useEffect(() => {
    setSignInModalVisible(showSignInModal);
  }, [showSignInModal]);

  const [isSignInModalVisible, setSignInModalVisible] = useState(false);
  const [isSignUpModalVisible, setSignUpModalVisible] = useState(false);

  return (
    <Box h="100vh">
      <Box bg="#FFFFFF" borderBottom="1px solid #E2E8F0" p="30px 20px 0px 20px">
        <Flex justify="center">
          <Box />
          <IconAblo />
          <HStack position="absolute" top="30px" right="20px">
            {rightSideNavbarContent}
            <Button
              onClick={() => setSignInModalVisible(true)}
              outlined
              h="30px"
              borderColor="#CBD5E0"
              color="#000000"
              fontWeight={600}
              _hover={{
                bg: 'transparent',
              }}
            >
              Sign In
            </Button>
          </HStack>
        </Flex>
        <Box mt="20px" w="100%" />
      </Box>
      <Box h="calc(100% - 80px)">{children}</Box>
      {isSignInModalVisible ? (
        <SignInModal
          onClose={() => {
            setSignInModalVisible(false);

            onCancelSignIn();
          }}
          onGoToSignUp={() => {
            setSignInModalVisible(false);
            setSignUpModalVisible(true);
          }}
          onSignedIn={() => {
            onSignedIn();
            setSignInModalVisible(false);
          }}
        />
      ) : null}
      {isSignUpModalVisible ? (
        <SignUpModal
          onClose={() => {
            setSignUpModalVisible(false);

            onCancelSignIn();
          }}
          onGoToSignIn={() => {
            setSignUpModalVisible(false);
            setSignInModalVisible(true);
          }}
          onSignedIn={onSignedIn}
        />
      ) : null}
    </Box>
  );
}
