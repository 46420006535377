import { Box, HStack, Text, VStack } from '@chakra-ui/react';

import Button from '@/components/button';
import { useHistory } from 'react-router-dom';
import { Design } from '@/lib';
import RemixCard from '@/components/remix/RemixCard';

import ModalContainer from '@/components/modals/ModalContainer';

type Props = {
  design: Design;
  onClose: () => void;
};

const IpPublishedModal = ({ design, onClose }: Props) => {
  const history = useHistory();

  const handleGoToProductDetails = () => history.push(`/products/${design.id}`);

  const handleGoToMyIPs = () => history.push('/designs?isPublished=true');

  return (
    <ModalContainer
      modalTitle="Your IP is published!"
      onClose={onClose}
      width={{ base: 'calc(100% - 20px)', md: 498 }}
    >
      <>
        <Text color="secondaryDarkGray.600" mb="21px" textStyle="body">
          You’ll earn 15% in royalties from each sale of your merch.
        </Text>
        <VStack w="100%">
          <Box maxW="185px">
            <RemixCard design={design} height={{ base: 298, lg: 278 }} hideVoteButton />
          </Box>
        </VStack>
        <HStack gap="9px" mt="29px" w={{ base: '100%', md: 'auto' }}>
          <Button onClick={handleGoToProductDetails} w="100%">
            Product details
          </Button>
          <Button onClick={handleGoToMyIPs} secondary w="100%">
            My IPs
          </Button>
        </HStack>
      </>
    </ModalContainer>
  );
};

export default IpPublishedModal;
