import {
  IImageFileToImageRequest,
  IImageGenerationResponse,
  IImageUrlToImageRequest,
} from '@space-runners/ablo-ts-sdk';
import { ablo } from './ablo-wrapper';

export const photoTransformerFile = async (
  params: IImageFileToImageRequest,
  contentType: string,
  referenceImageContentType?: string
): Promise<IImageGenerationResponse> => {
  const response = await ablo().photoTransformer.fromFile(
    params,
    contentType,
    referenceImageContentType
  );

  return response;
};

export const photoTransformerUrl = async (params: IImageUrlToImageRequest) => {
  const response = await ablo().photoTransformer.fromUrl(params);

  return response.images;
};
