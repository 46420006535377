const VoteToWinMerchVideo = () => (
  <video
    autoPlay
    style={{
      objectFit: 'cover',
    }}
    loop
    muted
    playsInline
    src="ABLO_BannerVoting.mp4"
  />
);

export default VoteToWinMerchVideo;
