import { Flex, Text, VStack, Center, HStack, Box, useBreakpointValue } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import { createCartItem, updateCartItem, deleteCartItem } from '@/api/cart';
import { useDesign } from '@/api/designs';

import { useState } from 'react';
import { useSizes } from '@/api/sizes';
import Button from '../button';
import LoadingOverlay from '../modals/LoadingOverlay';
import { TemplateColorSize } from '../types';
import { sortBy } from 'lodash';
import UpdateQuantityControl from './UpdateQuantityControl';
import { useMe } from '@/api/auth';

import { useQueryClient } from '@tanstack/react-query';
import IconCart from '../icons/IconCart';
import LoadingSpinner from '../ui/LoadingSpinner';
import { createMissingManufacturingImages } from '@/utils/design';
import ModalContainer from '../modals/ModalContainer';

interface AddToCartModalProps {
  designId: string;
  onClose: () => void;
  goBackText: string;
}

export default function AddToCartModal({ onClose, designId, goBackText }: AddToCartModalProps) {
  const { data: allSizes = [] } = useSizes();

  const [waiting, setWaiting] = useState(false);

  const { data: design, isLoading } = useDesign(designId);

  const queryClient = useQueryClient();

  const { data: user } = useMe();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const { template } = design || {};

  const { cart } = user;

  const { colors = [] } = template || {};

  const color = colors.find(({ id }) => id === design.templateColorId);

  const templateColorSizes = color?.sizes || [];

  const sizeOptions = templateColorSizes.map<TemplateColorSize>((item) => {
    const { sizeId } = item;

    const { name } = allSizes.find((size) => size.id === sizeId) || {};

    return {
      ...item,
      name,
    };
  });

  const history = useHistory();

  const handleUpdateCart = (cart) => {
    queryClient.setQueryData(['me'], { ...user, cart });
  };

  const handleUpdateQuantity = async (size: TemplateColorSize, quantity: number) => {
    const cartItem = cart.items.find(({ templateColorSizeId }) => templateColorSizeId === size.id);

    setWaiting(true);

    if (!cartItem) {
      const params = {
        designId: design.id,
        templateColorSizeId: size.id,
        quantity: 1,
        storefrontId: size.storefrontVariantId,
      };

      await createMissingManufacturingImages(design);

      const newCartItem = await createCartItem(params);

      handleUpdateCart({
        ...cart,
        items: [...cart.items, newCartItem],
      });

      setWaiting(false);

      return;
    }

    if (quantity === 0) {
      deleteCartItem(cartItem.id)
        .then(() =>
          handleUpdateCart({
            ...cart,
            items: cart.items.filter(
              (item) => item.templateColorSizeId !== cartItem.templateColorSizeId
            ),
          })
        )
        .finally(() => setWaiting(false));
    } else {
      updateCartItem(cartItem.id, quantity)
        .then((cartItem) =>
          handleUpdateCart({
            ...cart,
            items: cart.items.map((item) => {
              if (item.templateColorSizeId !== cartItem.templateColorSizeId) {
                return item;
              }

              return cartItem;
            }),
          })
        )
        .finally(() => setWaiting(false));
    }
  };

  const handleGoToCheckout = () => {
    if (isMobile) {
      history.push('/cart');

      return;
    }

    onClose();

    history.replace(`?showCart=true`);
  };

  return (
    <ModalContainer
      modalTitle="Select size"
      onClose={onClose}
      width={{ base: '343px', md: '480px' }}
    >
      <Box>
        {isLoading ? (
          <Center h="100%">
            <LoadingSpinner />
          </Center>
        ) : (
          <Box w="100%">
            <VStack
              gap="11px"
              justify="space-between"
              mt={{ base: '12px', md: '20px' }}
              mb="28px"
              w="100%"
            >
              {sortBy(sizeOptions, 'sizeId').map((size, index) => (
                <Flex justify="space-between" key={index} w="100%">
                  <Text textStyle="caption">{size.name}</Text>
                  <UpdateQuantityControl
                    onSetQuantity={(quantity) => handleUpdateQuantity(size, quantity)}
                    quantity={
                      cart.items.find(({ templateColorSizeId }) => templateColorSizeId === size.id)
                        ?.quantity
                    }
                  />
                </Flex>
              ))}
            </VStack>
            <Button onClick={handleGoToCheckout} w="100%">
              <HStack spacing="6px">
                <IconCart />
                <Text>Go to cart</Text>
              </HStack>
            </Button>
            <Button onClick={onClose} w="100%" secondary mt={{ base: '10px', md: '14px' }}>
              <Text>{goBackText}</Text>
            </Button>
          </Box>
        )}
        {waiting ? <LoadingOverlay /> : null}
      </Box>
    </ModalContainer>
  );
}
