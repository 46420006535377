import { mode } from '@chakra-ui/theme-tools';

import '@fontsource/dm-sans/latin-400.css';
import '@fontsource/dm-sans/latin-500.css';
import '@fontsource/dm-sans/latin-700.css';

import '@fontsource/inter/latin-700.css';

export const globalStyles = {
  colors: {
    brand: {
      300: '#054AC4',
      400: '#156CFE',
      500: '#4318FF',
      600: '#064AC4',
      700: '#003899',
    },
    black: {
      200: '#2E2E2E',
      300: '#454545',
      400: '#212121',
      500: '#212529',
      600: '#000000',
      700: '#2B3674',
    },
    gray: {
      50: '#EEEEEE',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#EAE9E9',
      400: '#E5EDFF',
      500: '#656565',
      600: '#4A5568',
      700: '#A9B3D2',
      900: '#171923',
    },
    secondaryGray: {
      100: '#C0C0C0',
      200: '#F8F8F8',
      300: '#F4F7FE',
      400: '#E9EDF7',
      500: '#E0E5F2',
      600: '#A3AED0',
      700: '#707EAE',
    },
    secondaryDarkGray: {
      100: '#4A4A4A',
      300: '#37415A',
      400: '#A8A8A8',
      500: '#8F9BBA',
      600: '#626F82',
      900: '#1B2559',
    },
    secondaryLightGray: '#EAEAEA',
    red: {
      200: '#FBEDEC',
      400: '#F12000',
      500: '#EE5D50',
      600: '#DD1D1D',
      700: '#B80A0A',
    },
    orange: {
      600: '#DD6B20',
    },
    blue: {
      500: '#3965FF',
    },
    green: {
      300: '#EBFFE7',
      600: '#1C8D02',
      900: '#136400',
    },
    disabled: '#AAA9AB',
    border: '#CBD5E0',
    borderLight: '#D3DEEA',
    borderSecondary: '#CBD6E3',
  },
  styles: {
    global: (props: object) => ({
      body: {
        overflowX: 'hidden',
        bg: mode('secondaryGray.300', 'navy.900')(props),
        color: '#212529',
        fontFamily: 'Inter',
        fontWeight: 400,
        letterSpacing: '-0.5px',
      },
      input: {
        color: 'black.600',
      },
      html: {
        fontFamily: 'Inter',
      },
    }),
  },
};
