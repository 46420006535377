import { mode } from '@chakra-ui/theme-tools';

export const buttonStyles = {
  Button: {
    baseStyle: {
      bg: 'brand.600',
      borderRadius: '14px',
      color: '#FFFFFF',
      fontWeight: 600,
      height: '50px',
      padding: '5px 14px',
      transition: '.25s all ease',
      boxSizing: 'border-box',
      _focus: {
        border: '2px solid brand.500',
        color: '#FFFFFF',
      },
      _active: {
        'background-image': 'linear-gradient(rgb(0 0 0/40%) 0 0)',
        border: '2px solid brand.500',
        color: '#FFFFFF',
      },
      _hover: {
        _disabled: {
          bg: '#D3DEEA',
        },
      },
      _disabled: {
        bg: '#D3DEEA',
        color: '#FFFFFF',
        opacity: 1,
      },
    },
    sizes: {
      md: {
        height: '50px',
      },
      sm: {
        height: '40px',
      },
    },
    variants: {
      solid: () => ({
        _hover: {
          border: '1px solid brand.400',
        },
      }),
      ghost: () => ({
        bg: 'transparent',
        height: 'auto',
        _disabled: {
          bg: 'transparent',
          border: 'none',
        },
      }),
      link: () => ({
        bg: 'transparent',
        color: 'brand.600',
        fontSize: 'sm',
        fontWeight: 600,
        px: '4px',
        py: 0,
        _active: {
          bg: 'transparent',
          'background-image': 'linear-gradient(rgb(0 0 0/40%) 0 0)',
        },
        _focus: {
          color: 'brand.600',
        },
        _hover: {
          color: 'brand.700',
          textDecoration: 'none',
        },
      }),
      outlined: {
        bg: '#FFFFFF',
        border: '1px solid',
        borderColor: 'black.600',
        color: 'black.600',
        _hover: {
          bg: '#EAE9E9',
          border: '1px solid',
          borderColor: 'black.600',
          color: '#000000',
        },
        _focus: {
          bg: '#EAE9E9',
          border: '2px solid',
          borderColor: 'brand.600',
          color: 'black.600',
        },
        _active: {
          border: '2px solid',
          borderColor: 'brand.600',
          color: 'black.600',
        },
      },
      secondary: {
        bg: 'transparent',
        border: '1px solid',
        borderColor: 'border',
        borderRadius: '8px',
        color: 'brand.600',
        fontSize: '14px',
        height: '40px',
        padding: '8px 16px',
        _hover: {
          bg: 'gray.300',
          border: '1px solid',
          borderColor: '#000000',
          color: 'black.600',
        },
        _focus: {
          bg: 'gray.300',
          border: '2px solid',
          borderColor: 'brand.600',
          color: 'black.600',
        },
        _active: {
          border: '2px solid',
          borderColor: 'brand.600',
          color: 'black.600',
        },
      },
      icon: {
        bg: 'transparent',
        border: '1px solid',
        borderColor: 'border',
        borderRadius: '5px',
        fontSize: '14px',
        height: '32px',
        padding: '8px',
        width: '32px',
        _hover: {
          bg: 'gray.300',
        },
      },
      brand: (props) => ({
        bg: mode('brand.500', 'brand.400')(props),
        color: 'white',
      }),

      light: (props) => ({
        bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
        color: mode('secondaryGray.900', 'white')(props),
      }),
    },
    defaultProps: {
      // Then here we set the base variant as the default
      variant: 'base',
    },
  },
};
